<template>
  <div class="interface-menu-navbar-header">
    <div class="cadre-block-filter">
      <div class="right-block">
        <!-- DATE DEBUT -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('RFTHDDF')">
          <v-menu
            v-model="isDatePickerDebutVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_debut"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleResetDate('date_debut')"
                color="#704ad1"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date_debut"
              @input="handleDateTimePicker(date_debut, 'date_debut')"
              no-title
              locale="fr"
              :allowed-dates="disabledStartDate"
              color="#704ad1"
              item-color="#704ad1"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <!-- DATE FIN -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('RFTHDDF')">
          <v-menu
            v-model="isDatePickerFinVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_fin"
                label="Date fin"
                outlined
                readonly
                hide-details
                v-on="on"
                v-bind="attrs"
                clearable
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleResetDate('date_fin')"
                color="#704ad1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_fin"
              @input="handleDateTimePicker(date_fin, 'date_fin')"
              no-title
              locale="fr"
              :allowed-dates="disabledEndDate"
              color="#704ad1"
              item-color="#704ad1"
            ></v-date-picker>
          </v-menu>
        </div>
        <!-- COONTENT LOADING AND MESSAGE ERROR -->
        <!-- <div
          v-if="getLoaderReglementRows"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->
        <v-progress-circular
          v-if="getLoaderReglementRows"
          indeterminate
          class="ml-2"
          color="#704ad1"
        ></v-progress-circular>
        <div class="notif-export-file-sci ml-2" v-if="getShowNotifExportFile">
          Préparation du fichier en cours ...
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="errorReglements">
            <ul v-if="Array.isArray(errorReglements)" class="mb-0">
              <li v-for="(e, index) in errorReglements" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ errorReglements }}</div>
          </div>
        </div>
      </div>
      <div class="left-block">
        <!-- SEARCH -->
        <div class="serach-input mr-2">
          <search
            v-if="checkPermission('RFTHFCP')"
            :value="num"
            @changeSearchValue="changeSearchValue"
            :dynamic="false"
            hiddenContent="true"
            placholderName="Rechercher par date paiement"
          />
        </div>
        <!-- ADD FACTURE -->
        <add-reglement v-if="checkPermission('RFTHNR')"></add-reglement>
        <!-- COMPTABILITE -->
        <comptabilise
          @end="handleResetCheck"
          :methods="changeStatutComptabilise"
          :selected="computedCheckRowsSelected"
          v-if="computedCheckRowsSelected.length > 0"
        />
        <!-- FILTER -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click="openPopover"
              class="block-icon mr-2 button-height-unset"
            >
              <IconFiltre></IconFiltre>
            </div>
          </template>
          <span>Choisir un Filtre</span>
        </v-tooltip>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="350"
          origin="top right"
          content-class="popover-menu-reglement-facture"
        >
          <v-card>
            <v-divider class="line-hr-filter"></v-divider>
            <v-card-text class="body-card filter-content-popup">
              <v-form>
                <!-- Type Paiement -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('RFTHFTR')"
                  v-model="isLocalStorageType"
                  :items="computedTypeReglement"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Type Paiement"
                  multiple
                  :loading="false"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageType.length - 0 }} Type(s) Paiement(s))
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageType.length - 0 }} Type(s) Paiement(s))
                    </span>
                  </template>
                </v-autocomplete>
                <!-- Partenaire -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('RFTHFP')"
                  v-model="isLocalStorageClients"
                  :items="computedFilterClient"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Partenaire"
                  multiple
                  :loading="false"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageClients.length - 0 }} Partenaire(s))
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageClients.length - 0 }} Partenaire(s))
                    </span>
                  </template>
                </v-autocomplete>
                <!-- Type Partenaire -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('RFTHFTP')"
                  v-model="isLocalStorageTypePartenaire"
                  :items="computedTypePartenaire"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Type Partenaire"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageTypePartenaire.length - 0 }} Type(s)
                      Partenaire(s))
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageTypePartenaire.length - 0 }} Type(s)
                      Partenaire(s))
                    </span>
                  </template>
                </v-autocomplete>
                <!-- Client -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('RFTHFTC')"
                  v-model="isLocalStorageAcheteur"
                  :items="computedFilterClient"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Client"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageAcheteur.length - 0 }} Client(s))
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageAcheteur.length - 0 }} Client(s))
                    </span>
                  </template>
                </v-autocomplete>
                <!-- Société -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('RFTHFTS')"
                  v-model="isLocalStorageVendeur"
                  :items="computedFilterVendeur"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Société"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageVendeur.length - 0 }} Société(s))
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageVendeur.length - 0 }} Société(s))
                    </span>
                  </template>
                </v-autocomplete>
                <!-- Comptabilisé -->
                <v-select
                  :clearable="true"
                  v-if="checkPermission('RFTHFC')"
                  v-model="isLocalStorageComptabilise"
                  :items="computedComptabilisé"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Comptabilisé"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
                <!-- Etat Règlement -->
                <v-select
                  :clearable="true"
                  v-if="checkPermission('RFTHFER')"
                  v-model="isLocalStorageEtat"
                  :items="computedEtatReglement"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Etat Règlement"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
                <!-- Etat Lettrage -->
                <v-select
                  :clearable="true"
                  v-if="checkPermission('RFTHFEL')"
                  v-model="isLocalStorageLettre"
                  :items="computedEtatLettrage"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Etat Lettrage"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-form>
            </v-card-text>
            <v-card-actions class="popover-action-filter">
              <v-btn
                class="ma-2 capitalize-text"
                outlined
                color="#5E35B1"
                @click="validerPopover"
              >
                Appliquer
              </v-btn>
              <v-btn
                class="ma-2 ml-2 capitalize-text"
                text
                @click="closePopover"
              >
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div class="content-cadre-table-reglement-fac">
      <!-- BLOCK SELON FILTER SELECTIONNEE -->
      <div>
        <div
          class="block-filter-style ml-0 mb-2"
          v-if="
            date_debut !== null ||
              date_fin !== null ||
              num !== null ||
              comptabilise !== null ||
              etat !== null ||
              lettre !== null
          "
        >
          <span class="phrase-style">Les filtres choisie sont : </span>
          <span class="item-filter-style" v-if="date_debut !== null"
            >Date début : {{ date_debut }}</span
          >
          <span class="item-filter-style" v-if="date_fin !== null"
            >Date fin : {{ date_fin }}</span
          >
          <span v-if="num !== null" class="item-filter-style"
            >Recherche : {{ num }}</span
          >
          <span v-if="comptabilise !== null" class="item-filter-style"
            >Comptabilisé : {{ comptabilise }}</span
          >
          <span v-if="etat !== null" class="item-filter-style"
            >Etat Règlement : {{ computedEtatReglementFilter(etat) }}</span
          >
          <span v-if="lettre !== null" class="item-filter-style"
            >Etat Lettrage : {{ lettre }}</span
          >
        </div>
      </div>
      <!-- BLOCK SELON FILTER POPOVER SELECTIONNEE -->
      <v-row
        class="filter-Block-icon"
        v-if="
          clients.length ||
            acheteur.length ||
            vendeur.length ||
            type_partenaire.length ||
            type.length
        "
      >
        <!-- Partenaire -->
        <v-col
          :cols="computedLengthFilter"
          v-if="clients.length"
          class="space-table-filter-reglement"
        >
          <span class="title-block-chip-filter ml-3">Partenaire(s) : </span>
          <v-sheet elevation="1" class="py-0 px-1 ml-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.text + 'proprietaire'"
                v-for="v in clients"
                close
                @click:close="
                  remove(v, 'clients', 'handleFilter', 'isLocalStorageClients')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- Type(s) partenaire -->
        <v-col
          :cols="computedLengthFilter"
          v-if="type_partenaire.length"
          class="space-table-filter-reglement"
        >
          <span class="title-block-chip-filter ml-3"
            >Type(s) partenaire(s):
          </span>
          <v-sheet elevation="1" class="py-0 px-1 ml-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.text + 'proprietaire'"
                v-for="v in type_partenaire"
                close
                @click:close="
                  remove(
                    v,
                    'type_partenaire',
                    'handleFilter',
                    'isLocalStorageTypePartenaire'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- TYPE PAIEMENT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="type.length"
          class="space-table-filter-reglement"
        >
          <span class="title-block-chip-filter ml-3"
            >Type(s) paiement(s) :
          </span>
          <v-sheet elevation="1" class="py-0 px-1 ml-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.text + 'proprietaire'"
                v-for="v in type"
                close
                @click:close="
                  remove(v, 'type', 'handleFilter', 'isLocalStorageType')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- SOCIETE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="vendeur.length"
          class="space-table-filter-reglement"
        >
          <span class="title-block-chip-filter ml-3">Société(s) : </span>
          <v-sheet elevation="1" class="py-0 px-1 ml-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.text + 'proprietaire'"
                v-for="v in vendeur"
                close
                @click:close="
                  remove(v, 'vendeur', 'handleFilter', 'isLocalStorageVendeur')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- CLIENT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="acheteur.length"
          class="space-table-filter-reglement"
        >
          <span class="title-block-chip-filter">Client(s) : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="a.text + 'proprietaire'"
                v-for="a in acheteur"
                close
                x-small
                @click:close="
                  remove(
                    a,
                    'acheteur',
                    'handleFilter',
                    'isLocalStorageAcheteur'
                  )
                "
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ a.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- TABLE REGLEMENT FACTURE -->
      <div
        class="groupe-content-reglement-fac"
        :class="{
          'padding-table':
            isLocalStorageClients.length ||
            isLocalStorageAcheteur.length ||
            isLocalStorageVendeur.length ||
            isLocalStorageTypePartenaire.length ||
            isLocalStorageType.length
        }"
      >
        <div class="btn-color-fac">
          <div>
            <div class="dot-vert-big" title="Règlement lettré"></div>
            <hr class="ligne" />
            <div class="dot-red-big" title="Règlement non lettré"></div>
            <hr class="ligne" />
            <div
              class="dot-org-big"
              title="Règlement fournisseur non validées"
            ></div>
          </div>
        </div>
        <div class="table-reglement-fac">
          <!-- {{ reglements }} -- -->
          <v-data-table
            v-model="selectedTable"
            :headers="computedFields"
            :items="reglements"
            :items-per-page="per_page"
            :class="{
              'height-filter-tous': returnTableChips && returnTableFiltre,
              'height-filter-chips-table':
                returnTableChips && !returnTableFiltre,
              'height-filter-table': returnTableFiltre && !returnTableChips
            }"
            class="table-facture"
            hide-default-footer
            hide-default-header
            :calculate-widths="false"
            :fixed-header="true"
            hide-action
            :no-data-text="
              getLoaderReglementRows
                ? 'Chargement... Veuillez patienter'
                : `Il n'y a aucune donnée à afficher dans ce tableau.`
            "
          >
            <!-- HEADERS TABLE -->
            <template v-slot:[`header`]="{ props }">
              <tr class="header-reglement">
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :style="{ width: header.width, padding: header.padding }"
                  :class="{
                    'sticky-header-1': header.value === 'check_all',
                    'sticky-header-reglement-2': header.value === 'id'
                  }"
                >
                  <div v-if="header.value === 'check_all'">
                    <v-checkbox
                      class="checked-global ml-1"
                      v-model="checkAllReglement"
                      @change="checkAllFunction"
                      color="#704ad1"
                      :true-value="true"
                  :false-value="false"
                      hide-details
                    ></v-checkbox>
                  </div>
                  <div
                    v-else
                    :class="{
                      'd-flex justify-content-center align-items-center':
                        header.sortable === true,
                      'd-flex justify-content-center':
                        header.sortable === false,
                      'underline-selected-colomn': header.value === column
                    }"
                  >
                    <span
                      class="mr-2 text-center"
                      :style="{
                        width:
                          header.value === 'actions' ? header.width : 'auto'
                      }"
                    >
                      {{ header.text }}</span
                    >

                    <font-awesome-icon
                      v-if="header.sortable === true"
                      @click="listOrder(header.value, 'ASC')"
                      icon="arrow-up"
                      :class="
                        header.value === column && order === 'ASC'
                          ? 'style-arrow-selected'
                          : 'style-arrow-not-selected'
                      "
                    />
                    {{ ' ' }}
                    <font-awesome-icon
                      @click="listOrder(header.value, 'DESC')"
                      v-if="header.sortable === true"
                      icon="arrow-down"
                      :class="
                        header.value === column && order === 'DESC'
                          ? 'style-arrow-selected'
                          : 'style-arrow-not-selected'
                      "
                    />
                  </div>
                </th>
              </tr>
            </template>
            <!-- BODY TABLE -->
            <template v-slot:[`item.check_all`]="{ item }">
              <td class="custom-cell sticky-header-1">
                <v-checkbox
                  class="checked-groupe"
                  v-model="item.check"
                  @change="checkRows(item)"
                  color="#704ad1"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                ></v-checkbox>
              </td>
            </template>
            <template v-slot:[`item.id`]="{ item }">
              <td class="custom-cell sticky-header-reglement-2">
                <div>
                  <span class="dot-vert" v-if="item.balance == 0"> </span
                  ><span>
                    <span class="dot-red" v-if="item.balance != 0"> </span
                    ><span>
                      <span
                        class="dot-org"
                        v-if="
                          item.type_partenaire == 'Fournisseur' &&
                            item.confirmation_fournisseur == false
                        "
                      >
                      </span
                      ><span></span> </span
                  ></span>
                </div>

                {{ item.id ? item.id : '-' }}
              </td>
            </template>
            <template v-slot:[`item.client`]="{ item }">
              <td class="custom-cell">{{ item.client ? item.client : '-' }}</td>
            </template>
            <template v-slot:[`item.societe`]="{ item }">
              <td class="custom-cell">
                {{ item.societe ? item.societe : '-' }}
              </td>
            </template>
            <template v-slot:[`item.type_partenaire`]="{ item }">
              <td class="custom-cell">
                {{ item.type_partenaire ? item.type_partenaire : '-' }}
              </td>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <td class="custom-cell">
                {{ item.type ? item.type : '-' }}
              </td>
            </template>
            <template v-slot:[`item.montant`]="{ item }">
              <td class="custom-cell">
                {{ item.montant | numberWithSpaces }}
                {{ item.currency }}
              </td>
            </template>
            <template v-slot:[`item.balance`]="{ item }">
              <td class="custom-cell">
                {{ item.balance | numberWithSpaces }}
                {{ item.currency }}
              </td>
            </template>
            <template v-slot:[`item.payment_date`]="{ item }">
              <td class="custom-cell">
                {{ item.payment_date ? item.payment_date : '-' }}
              </td>
            </template>
            <template v-slot:[`item.reference`]="{ item }">
              <td class="custom-cell">
                {{ item.reference ? item.reference : '-' }}
              </td></template
            >
            <template v-slot:[`item.payment_note`]="{ item }">
              <td class="custom-cell">
                {{ item.payment_note ? item.payment_note : '-' }}
              </td></template
            >

            <template v-slot:[`item.comptabilise`]="{ item }">
              <td class="custom-cell">
                {{ item.comptabilise ? item.comptabilise : '-' }}
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <td class="custom-cell">
                <div class="flex-div">
                  <div v-if="checkPermission('RFTHMR')">
                    <v-btn
                      v-if="checkPermission('RFTHMR')"
                      small
                      title="Modifier un Paiement"
                      class="block-icon-reglement-fac"
                      @click.prevent.stop="handleUpdate(item)"
                    >
                      <font-awesome-icon icon="pencil-alt" />
                    </v-btn>
                  </div>
                  <v-btn
                    v-if="checkPermission('RFTHSR')"
                    v-show="item.factures_regles.length == 0"
                    small
                    title="Supprimer un Paiement"
                    class="block-icon-reglement-fac"
                    @click.prevent.stop="handleDelete(item)"
                  >
                    <font-awesome-icon icon="trash" />
                  </v-btn>
                  <div v-if="checkPermission('RFTHPJ')">
                    <PieceJointe
                      :permission="checkPermission('RFTHPJ')"
                      :item="computedItemsFiles(item)"
                      :show="false"
                      :reglement="item"
                    ></PieceJointe>
                  </div>
                </div>
              </td>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <!-- FOOTER PAGINATION PERPAGE -->
    <div class="footer-style-table">
      <div
        class="text-center pagination-ta
      ble"
      >
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="per_page"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          outlined
          dense
          color="#704ad1"
          item-color="#704ad1"
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetX: true
          }"
        ></v-select>
      </div>
    </div>
    <!-- SUPPRIMER UN PAIEMENT FACTURE -->
    <v-dialog
      v-model="deleteReglementModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer un Paiement</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('deleteReglement')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          Êtes-vous sur de vouloir supprimer ce Paiement ?
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="submitDeleteReglement"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn
            text
            @click="hideModal('deleteReglement')"
            :class="{ loader: loading }"
            >Annuler</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- UPDATE PAYMENT  -->
    <v-dialog
      v-model="updateReglementFacture"
      max-width="1500px"
      :scrollable="show == true ? false : true"
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"> Modifier Paiement</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal('updateReglementFacture')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <div v-if="show == true" class="loading-custom-reglement-block">
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#704ad1'"
              class="loeder"
            />
          </div>

          <v-form v-if="reglementToUpdate != null" class="mt-2">
            <v-row>
              <v-col col xs="2" sm="2" md="2">
                <v-select
                  placeholder=""
                  :items="computedTypePartenaire"
                  label="Type Partenaire"
                  v-model="reglementToUpdate.type_partenaire"
                  outlined
                  :persistent-placeholder="true"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                  :disabled="true"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col col xs="2.5" sm="2.5" md="2.5">
                <v-text-field
                  label="Partenaire"
                  type="text"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  v-model="reglementToUpdate.client"
                  :disabled="true"
                  color="#704ad1"
                ></v-text-field>
              </v-col>

              <v-col col xs="2" sm="2" md="2">
                <v-select
                  placeholder=""
                  :items="computedTypeReglement"
                  label="Type paiement"
                  v-model="reglementToUpdate.type_id"
                  outlined
                  required
                  :rules="[v => !!v || 'Type paiement obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  :persistent-placeholder="true"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <lcr
                v-if="
                  reglementToUpdate.echeances.length == 0 &&
                    facturesReglement.length > 0
                "
                :montantFix="true"
                :reglement="computedReg"
                :factures="facturesReglement"
                @saveDataForLcr="saveDataForLcr"
                :resetModalLcr="resetModalLcr"
              />
              <v-col
                col
                xs="2.5"
                sm="2.5"
                md="2.5"
                v-if="!computedPaiementNameSelected.includes('LCR')"
              >
                <v-select
                  placeholder=""
                  required
                  :rules="[v => !!v || 'Conditions du paiement obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  :items="computedConditionPaiement"
                  label="Conditions du paiement"
                  v-model="reglementToUpdate.condition_id"
                  outlined
                  :persistent-placeholder="true"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col
                col
                xs="2"
                sm="2"
                md="2"
                v-if="getConditionAmountNonLettre"
              >
                <v-text-field
                  label="Montant non alloué"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :disabled="true"
                  :value="getAmountNonLettrerReglemnt | numberWithSpaces"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col
                col
                :xs="getConditionAmountNonLettre ? '2' : '4'"
                :sm="getConditionAmountNonLettre ? '2' : '4'"
                :md="getConditionAmountNonLettre ? '2' : '4'"
              >
                <v-text-field
                  @input="calculRestePayer"
                  label="Montant à payer"
                  type="number"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  v-model="reglementToUpdate.montant"
                  min="0"
                  step="0.00001"
                  required
                  :rules="[v => !!v || 'Montant à payer est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  :disabled="true"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col col xs="1.25" sm="1.25" md="1.25">
                <v-select
                  placeholder=""
                  :items="computedUnite"
                  label="Unité"
                  v-model="reglementToUpdate.currency"
                  outlined
                  :persistent-placeholder="true"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                  :required="true"
                  :rules="[v => !!v || 'Unité est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col col xs="2.5" sm="2.5" md="2.5">
                <v-text-field
                  label="Réference"
                  type="text"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  v-model="reglementToUpdate.reference"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <div class="label-for-no-vuetify ">
                  <div class="custom-label">Date paiement</div>
                  <date-picker
                    id="date_paiement"
                    value-type="format"
                    format="DD-MM-YYYY"
                    v-model="reglementToUpdate.payment_date"
                    required
                    type="date"
                    class="inputDatePaiement"
                  ></date-picker>
                </div>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <v-text-field
                  label="Reste"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :disabled="true"
                  :value="reglementToUpdate.balance | numberWithSpaces"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <v-text-field
                  label="Solde courant"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :disabled="true"
                  :value="reglementToUpdate.solde_init | numberWithSpaces"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <v-text-field
                  label="Montant Echu"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :disabled="true"
                  :value="reglementToUpdate.solde_echeance | numberWithSpaces"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              v-if="
                reglementToUpdate &&
                  reglementToUpdate.canConfirm == true &&
                  reglementToUpdate.type_partenaire &&
                  reglementToUpdate.type_partenaire == 'Fournisseur'
              "
            >
              <v-col col xs="4" sm="4" md="4"></v-col>
              <v-col col xs="4" sm="1.25" md="1.25">
                <v-select
                  placeholder=""
                  :items="computedListConfirm"
                  label="Confirmation"
                  v-model="reglementToUpdate.confirmation_fournisseur"
                  outlined
                  :persistent-placeholder="true"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                  :required="true"
                  :rules="[v => !!v || 'Confirmation est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col col xs="4" sm="4" md="4"></v-col>
            </v-row>
            <badge
              v-if="getConditionAmountNonLettre"
              class="mt-3 mb-3"
              color="purple"
            />
            <v-row>
              <v-col col xs="12" sm="12" md="12">
                <v-textarea
                  outlined
                  label="Note"
                  v-model="reglementToUpdate.payment_note"
                  :persistent-placeholder="true"
                  clearable
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-tabs
              align-with-title
              background-color="#f1edfb"
              color="#704ad1"
              class="tabs-add-reglement-fac mt-2"
              v-model="indexTab"
            >
              <v-tab active>
                <span class="tab-text-bold">
                  {{ 'Factures régler ' + reglementToUpdate.client }}
                </span>
              </v-tab>
              <v-tab-item>
                <div
                  class="btn-end-postion"
                  v-if="
                    reglementToUpdate &&
                      computedCheckRows.length != 0 &&
                      ((reglementToUpdate.canConfirm == true &&
                        reglementToUpdate.type_partenaire == 'Fournisseur') ||
                        reglementToUpdate.type_partenaire == 'Client') &&
                      reglementToUpdate.comptabilise != 'Comptablisé'
                  "
                >
                  <v-btn
                    class="button-icon-style"
                    dark
                    small
                    color="#704ad1"
                    title="Annuler Paiement"
                    @click="openModalConfirmAnnulation"
                    v-if="reglementToUpdate.comptabilise != 'Comptablisé'"
                  >
                    <font-awesome-icon icon="wrench" class="file-medical" />
                  </v-btn>
                </div>
                <div class="table-reglement-fac-dialog mt-2">
                  <v-data-table
                    v-model="selectedTableU"
                    :headers="fields"
                    :items="reglementToUpdate.factures_regles"
                    class="table-facture"
                    hide-default-footer
                    :calculate-widths="false"
                    :fixed-header="true"
                    hide-action
                    disable-sort
                    :items-per-page="reglementToUpdate.factures_regles.length"
                    no-data-text="Il n'y a aucun enregistrement à afficher"
                  >
                    <!-- HEADERS TABLE -->
                    <template v-slot:[`header.check_all`]="{ header }">
                      <span v-if="header.value != 'check_all'">
                        {{ header.label }}</span
                      >
                      <div
                        v-else
                        class="checkbox-class-show mx-auto"
                        :class="{ 'checkbox-class-show-checked': checkAll }"
                        @click="
                          checkAll = !checkAll;
                          selectAll();
                        "
                      >
                        <font-awesome-icon
                          icon="check"
                          v-if="checkAll"
                          class="check"
                        />
                      </div>
                    </template>
                    <!-- BODY TABLE -->
                    <template v-slot:[`item.check_all`]="{ item }">
                      <td class="custom-cell sticky-header-1">
                        <div
                          class="checkbox-class-show mx-auto"
                          :class="{ 'checkbox-class-show-checked': item.check }"
                          @click="item.check = !item.check"
                        >
                          <font-awesome-icon
                            icon="check"
                            v-if="item.check"
                            class="check"
                          />
                        </div>
                      </td>
                    </template>
                    <template v-slot:[`item.num`]="{ item }">
                      <td class="custom-cell">
                        <div class="flex-div">
                          <PopoverReglementFacture
                            v-show="
                              item && item.reglements && item.reglements.length
                            "
                            :factureReglement="item.reglements"
                            :permissionModifier="false"
                            dataInterface="reglementFacture"
                          >
                          </PopoverReglementFacture>
                          {{ item.num }}
                        </div>
                      </td>
                    </template>
                    <template v-slot:[`item.total_ttc`]="{ item }">
                      <td class="custom-cell">
                        {{ item.total_ttc | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.montant_regle`]="{ item }">
                      <td class="custom-cell">
                        {{ item.montant_regle | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.montant_a_regle`]="{ item }">
                      <td class="custom-cell">
                        {{ item.montant_a_regle | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.reste`]="{ item }">
                      <td class="custom-cell">
                        {{ item.reste | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.vendeur`]="{ item }">
                      <td class="custom-cell">
                        {{ item.vendeur ? item.vendeur : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.client`]="{ item }">
                      <td class="custom-cell">
                        {{ item.acheteur ? item.acheteur : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.delais_paiement`]="{ item }">
                      <td class="custom-cell">
                        {{
                          formateDateGetters(item.date_echeance)
                            ? formateDateGetters(item.date_echeance)
                            : '-'
                        }}
                      </td>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
              <!--  -->
              <v-tab @click="getfactures">
                <span class="tab-text-bold">
                  {{ 'Factures à régler ' + reglementToUpdate.client }}
                </span>
              </v-tab>
              <v-tab-item>
                <div class="btn-end-postion mt-2">
                  <v-text-field
                    label="Rechercher"
                    placeholder="Rechercher"
                    :persistent-placeholder="true"
                    outlined
                    v-model="search"
                  ></v-text-field>
                </div>
                <div class="table-reglement-fac-dialog">
                  <v-data-table
                    v-model="selectedTable"
                    :headers="fields"
                    :items="facturesReglementComputed"
                    class="table-facture"
                    hide-default-footer
                    :calculate-widths="false"
                    :fixed-header="true"
                    hide-action
                    disable-sort
                    :items-per-page="facturesReglementComputed.length"
                    no-data-text="Il n'y a aucun enregistrement à afficher"
                  >
                    <!-- HEADERS TABLE -->
                    <template v-slot:[`header.check_all`]="{ header }">
                      <span v-if="header.value != 'check_all'">
                        {{ header.label }}</span
                      >
                      <div v-else>
                        <div
                          class="checkbox-class-show mx-auto"
                          :class="{ 'checkbox-class-show-checked': checkAll }"
                          @click="
                            checkAll = !checkAll;
                            handleCheckAll();
                          "
                        >
                          <font-awesome-icon
                            icon="check"
                            v-if="checkAll"
                            class="check"
                          />
                        </div>
                      </div>
                    </template>
                    <!-- BODY TABLE -->
                    <template v-slot:[`item.check_all`]="{ item }">
                      <td class="custom-cell sticky-header-1">
                        <div
                          class="checkbox-class-show mx-auto"
                          :class="{ 'checkbox-class-show-checked': item.check }"
                          @click="
                            item.check = !item.check;
                            checkFacture(item);
                          "
                        >
                          <font-awesome-icon
                            icon="check"
                            v-if="item.check"
                            class="check"
                          />
                        </div>
                      </td>
                    </template>
                    <template v-slot:[`item.num`]="{ item }">
                      <td class="custom-cell">{{ item.num }}</td>
                    </template>
                    <template v-slot:[`item.total_ttc`]="{ item }">
                      <td class="custom-cell">
                        {{ item.total_ttc | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.montant_regle`]="{ item }">
                      <td class="custom-cell">
                        {{ item.montant_regle | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.montant_a_regle`]="{ item }">
                      <EditableInput
                        :editable="
                          reglementToUpdate.montant != null &&
                            item.check == true &&
                            reglementToUpdate.balance != 0
                        "
                        champName="montant_a_regle"
                        :item="item"
                        :value="item.montant_a_regle"
                        type="text"
                        editableType="input"
                        :updateFunction="updateMontantRegler"
                        :defaultColor="true"
                      />
                    </template>
                    <template v-slot:[`item.reste`]="{ item }">
                      <td class="custom-cell">
                        {{ item.reste | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.vendeur`]="{ item }">
                      <td class="custom-cell">
                        {{ item.vendeur ? item.vendeur : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.client`]="{ item }">
                      <td class="custom-cell">
                        {{ item.acheteur ? item.acheteur : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.delais_paiement`]="{ item }">
                      <td class="custom-cell">
                        {{
                          formateDateGetters(item.date_echeance)
                            ? formateDateGetters(item.date_echeance)
                            : '-'
                        }}
                      </td>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
              <!-- PIECE JOINT -->
              <v-tab
                ><span class="tab-text-bold">Fichiers jointes</span>
              </v-tab>
              <v-tab-item>
                <PieceJointe
                  :permission="true"
                  :item="reglementToUpdate.files"
                  :reglement="reglementToUpdateOrigine"
                  :show="true"
                  typeVoir="updateFiles"
                  @showFiles="showFiles"
                  @showFilesRecent="showFilesRecent"
                ></PieceJointe>
              </v-tab-item>
              <!-- ECHEANCE PAYMENT  -->
              <v-tab
                v-if="
                  computedPaiementNameSelected.includes('LCR') &&
                    reglementToUpdate.echeances
                "
              >
                <span class="tab-text-bold"> Échéancier de paiement </span>
              </v-tab>
              <v-tab-item
                v-if="
                  computedPaiementNameSelected.includes('LCR') &&
                    reglementToUpdate.echeances
                "
              >
                <div
                  class="btn-end-postion"
                  v-if="computedCheckRowsEcheans.length != 0"
                >
                  <v-btn
                    class="button-icon-style"
                    dark
                    small
                    color="#704ad1"
                    title="Téléchargement PDF d'écheances"
                    @click.prevent.stop="exportMultipleEcheance"
                  >
                    <font-awesome-icon
                      icon="arrow-alt-circle-down"
                      class="file-medical"
                    />
                  </v-btn>
                </div>
                <div class="table-reglement-fac-dialog mt-2">
                  <v-data-table
                    v-model="selectedTableE"
                    :headers="fieldsEcheance"
                    :items="reglementToUpdate.echeances"
                    class="table-facture"
                    hide-default-footer
                    :calculate-widths="false"
                    :fixed-header="true"
                    hide-action
                    disable-sort
                    :items-per-page="reglementToUpdate.echeances.length"
                    no-data-text="Il n'y a aucun enregistrement à afficher"
                  >
                    <!-- HEADERS TABLE -->
                    <template v-slot:[`header.check_all`]="{ header }">
                      <th>
                        <span v-if="header.value != 'check_all'">
                          {{ header.label }}</span
                        >
                        <div
                          v-else
                          class="checkbox-class-show mx-auto"
                          :class="{
                            'checkbox-class-show-checked': checkAllEcheance
                          }"
                          @click="
                            checkAllEcheance = !checkAllEcheance;
                            functionCheckAllEcheance();
                          "
                        >
                          <font-awesome-icon
                            icon="check"
                            v-if="checkAllEcheance"
                            class="check"
                          />
                        </div>
                      </th>
                    </template>
                    <!-- BODY TABLE -->
                    <template v-slot:[`item.check_all`]="{ item }">
                      <td class="custom-cell sticky-header-1">
                        <div
                          class="checkbox-class-show mx-auto"
                          :class="{ 'checkbox-class-show-checked': item.check }"
                          @click="
                            item.check = !item.check;
                            checkRowsEcheances(item);
                          "
                        >
                          <font-awesome-icon
                            icon="check"
                            v-if="item.check"
                            class="check"
                          />
                        </div>
                      </td>
                    </template>
                    <template v-slot:[`item.libelle`]="{ item }">
                      <td class="custom-cell">
                        {{ item.libelle ? item.libelle : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.sequence`]="{ item }">
                      <td class="custom-cell">
                        {{ item.sequence ? item.sequence : '-' }}
                        <div class="block-icon-table-facture">
                          <font-awesome-icon
                            icon="arrow-alt-circle-down"
                            @click.prevent="downloadFacture(item.id)"
                            class="icon-style-color mr-1"
                            title="Télécharger la facture"
                          />
                        </div>
                      </td>
                    </template>
                    <template v-slot:[`item.date_creation`]="{ item }">
                      <td class="custom-cell">
                        {{ item.date_creation ? item.date_creation : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.date_echance`]="{ item }">
                      <date-picker
                        v-model="item.date_echeance"
                        value-type="format"
                        type="date"
                        required
                        input-class="custom-date-picker-filter"
                        class="
                                custom-date-picker-calender-filter
                                mt-2
                                mb-2
                              "
                      ></date-picker>
                    </template>
                    <template v-slot:[`item.client`]="{ item }">
                      <td class="custom-cell">
                        {{
                          reglementToUpdate && reglementToUpdate.client
                            ? reglementToUpdate.client
                            : item.client
                        }}
                      </td>
                    </template>
                    <template v-slot:[`item.devise`]="{ item }">
                      <td class="custom-cell">
                        {{ item.devise ? item.devise : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.rib`]="{ item }">
                      <td class="custom-cell">
                        {{
                          item.rib && item.rib.rib_bank
                            ? item.rib.rib_bank
                            : '-'
                        }}
                      </td>
                    </template>
                    <template v-slot:[`item.amount`]="{ item, index }">
                      <td class="custom-cell">
                        <input
                          min="0"
                          step="0.00001"
                          :max="reglementToUpdate.montant"
                          type="number"
                          autocomplete="off"
                          v-model="item.amount"
                          @input="changeMontantEcheance(index)"
                        />
                      </td>
                    </template>
                    <template v-slot:[`item.date_envoi`]="{ item }">
                      <date-picker
                        v-model="item.date_envoi"
                        value-type="format"
                        type="date"
                        required
                        input-class="custom-date-picker-filter"
                        class="
                                custom-date-picker-calender-filter
                                mt-2
                                mb-2
                              "
                      ></date-picker>
                    </template>
                    <template v-slot:[`item.date_signature`]="{ item }">
                      <date-picker
                        v-model="item.date_signature"
                        value-type="format"
                        type="date"
                        required
                        input-class="custom-date-picker-filter"
                        class="
                                custom-date-picker-calender-filter
                                mt-2
                                mb-2
                              "
                      ></date-picker>
                    </template>
                    <template v-slot:[`item.date_valeur`]="{ item }">
                      <date-picker
                        v-model="item.date_valeur"
                        value-type="format"
                        type="date"
                        required
                        input-class="custom-date-picker-filter"
                        class="
                                custom-date-picker-calender-filter
                                mt-2
                                mb-2
                              "
                      ></date-picker>
                    </template>
                    <template v-slot:[`body.append`]="{}">
                      <b-tr
                        v-if="
                          reglementToUpdate &&
                            reglementToUpdate.echeances.length > 0
                        "
                      >
                        <b-td class="custom-cell">-</b-td>
                        <b-td class="custom-cell">-</b-td>
                        <b-td class="custom-cell">-</b-td>
                        <b-td class="custom-cell">-</b-td>
                        <b-td class="custom-cell">-</b-td>
                        <b-td class="custom-cell">-</b-td>
                        <b-td class="custom-cell">-</b-td>
                        <b-td class="custom-cell">-</b-td>
                        <b-td class="custom-cell"
                          >{{ reglementToUpdate.totalEcheance }}
                        </b-td>
                        <b-td class="custom-cell">-</b-td>
                        <b-td class="custom-cell">-</b-td>
                        <b-td class="custom-cell">-</b-td>
                        <b-td class="custom-cell">-</b-td>
                      </b-tr>
                      <!-- <tr v-if="reglementToUpdate.echeances.length == 0">
                        <td :colspan="headers.length">
                          Il n'y a aucune tranche à afficher
                        </td>
                      </tr> -->
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleSubmitUpdateReglement"
            :disabled="
              reglementToUpdate &&
                reglementToUpdate.comptabilise == 'Comptablisé'
            "
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn
            :disabled="
              reglementToUpdate &&
                reglementToUpdate.comptabilise == 'Comptablisé'
            "
            v-if="
              reglementToUpdate &&
                ((reglementToUpdate.canConfirm == true &&
                  reglementToUpdate.type_partenaire &&
                  reglementToUpdate.type_partenaire == 'Fournisseur') ||
                  reglementToUpdate.type_partenaire == 'Client')
            "
            text
            @click.prevent.stop="handleModalAnnulerTotal"
          >
            <span>
              Annuler le Paiement
              <div v-if="loadingAnnuler" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CONFIRMATION ANNULER PAIEMENT-->
    <v-dialog
      v-model="confirmAnnulation"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Annuler le(s) Paiement(s) sélectionnés</v-label
          >
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="annulerConfirmation"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          Vous devez confirmer l'annulation de ce(s) Paiement(s) ?
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="submitAnnulationReglement"
            :class="{ loader: annulerReglement }"
            :loading="annulerReglement"
          >
            Valider
          </v-btn>
          <v-btn
            text
            @click="annulerConfirmation"
            :class="{ loader: loadingAnnuler }"
            >Annuler</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ANNULATION PAIEMENT  -->
    <v-dialog
      v-model="annulationTotal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Annulation Totale de Paiement</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="annulerConfirmationTotal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          Vous devez confirmer l'annulation totale de ce Paiement ?
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="AnnulerReglementTotal"
            :class="{ loader: loadingValideAnnuler }"
          >
            Valider
          </v-btn>
          <v-btn
            text
            @click="annulerConfirmationTotal"
            :class="{ loader: loadingValideAnnuler }"
            >Annuler</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment';
import comptabilise from '../ThProject/comptabilité/component/comptabilise.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Reglement-Facture',
  data() {
    return {
      indexTab: 'tab-0',
      fields: [
        {
          value: 'check_all',
          text: '',
          width: '40px !important',
          class: 'sticky-header-1'
        },
        {
          value: 'num',
          text: 'Numéro'
        },
        {
          value: 'total_ttc',
          text: 'Montant Total'
        },
        {
          value: 'reste',
          text: 'Reste'
        },
        {
          value: 'montant_regle',
          text: 'Montant Réglé'
        },
        {
          value: 'montant_a_regle',
          text: 'Montant à Régler'
        },
        {
          value: 'vendeur',
          text: 'Société'
        },
        {
          value: 'client',
          text: 'Client'
        },
        {
          value: 'delais_paiement',
          text: 'Délais du paiement'
        }
      ],
      fieldsEcheance: [
        {
          value: 'check_all',
          text: '',
          width: '40px !important',
          class: 'sticky-header-1'
        },
        {
          value: 'libelle',
          text: 'Libellé'
        },
        {
          value: 'sequence',
          text: 'Séquence'
        },
        {
          value: 'date_creation',
          text: 'Date de création'
        },
        { value: 'date_echance', text: "Date d'échéance" },
        {
          value: 'client',
          text: 'Client/Fournisseur'
        },
        {
          value: 'devise',
          text: 'Devise'
        },
        {
          value: 'rib',
          text: 'Banque du tiré'
        },
        {
          value: 'amount',
          text: 'Montant'
        },
        {
          value: 'date_envoi',
          text: "Date d'envoi"
        },
        {
          value: 'date_signature',
          text: 'Date de signature'
        },
        {
          value: 'date_valeur',
          text: 'Date valeur'
        }
      ],
      annulationTotal: false,
      confirmAnnulation: false,
      resetModalLcr: false,
      showEcheance: false,
      updateReglementFacture: false,
      deleteReglementModal: false,
      expanded: [],
      singleExpand: false,
      selectedTable: [],
      selectedTableU: [],
      selectedTableE: [],
      menu: false,
      factureToShow: null,
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      lettre: null,
      search: null,
      dataLcr: null,
      reglement: null,
      checkAllEcheance: false,
      checkAllReglement: false,
      annulerReglement: false,
      loadingValideAnnuler: false,
      loadingAnnuler: false,
      loading: false,
      show: false,
      checkAll: false,
      num: null,
      reglementToDelete: null,
      clients: [],
      type_partenaire: [],
      date_debut: null,
      error: [],
      date_fin: null,
      reglementToUpdate: null,
      reglementToUpdateOrigine: null,
      type: [],
      comptabilise: null,
      page: 1,
      per_page: 10,
      files: [],
      vendeur: [],
      acheteur: [],
      etat: null,
      column: 'id',
      order: 'DESC',
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 }
      ],
      isLocalStorageClients: [],
      isLocalStorageAcheteur: [],
      isLocalStorageVendeur: [],
      isLocalStorageTypePartenaire: [],
      isLocalStorageType: [],
      isLocalStorageComptabilise: null,
      isLocalStorageEtat: null,
      isLocalStorageLettre: null
    };
  },
  computed: {
    ...mapGetters([
      'getLoaderReglementRows',
      'checkPermission',
      'getShowNotifExportFile',
      'reglements',
      'typesCompta',
      'getReglementTotalRows',
      'clientsReglement',
      'soldeClient',
      'facturesReglement',
      'TypeReglement',
      'errorReglements',
      'clientFilter',
      'soldeEcheance',
      'formateDateGetters',
      'getAcheteurReglement',
      'getAmountNonLettrerReglemnt',
      'ConditionPaiement',
      'getConditionAmountNonLettre',
      'countNonEmptyArrays'
    ]),
    computedLengthFilter() {
      return this.countNonEmptyArrays(
        this.clients,
        this.acheteur,
        this.vendeur,
        this.type_partenaire,
        this.type
      );
    },
    //COMPUTED UPDATE PAYMENT
    computedCheckRowsEcheans() {
      let tableCheck = [];
      this.reglementToUpdate.echeances?.map(reglement => {
        if (reglement.check == true) {
          tableCheck.push(reglement);
        }
      });
      return tableCheck;
    },

    computedFiles() {
      return this.facturesReglement.files;
    },
    facturesReglementComputed() {
      if (this.search != null && this.search != '') {
        return this.facturesReglement.filter(item =>
          item.num.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.facturesReglement;
    },

    computedCheckRows() {
      let tableCheck = [];
      this.reglementToUpdate.factures_regles?.map(reglement => {
        if (reglement.check == true && reglement.reglements.length) {
          tableCheck.push(reglement);
        }
      });
      return tableCheck;
    },

    computedListConfirm() {
      let confirmList = [
        {
          text: 'Oui',
          value: true
        },
        {
          text: 'Non',
          value: false
        }
      ];
      return confirmList;
    },
    computedUnite() {
      let unite = [
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' }
      ];
      return unite;
    },
    computedConditionPaiement() {
      return this.ConditionPaiement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedPaiementNameSelected() {
      if (this.reglementToUpdate && this.reglementToUpdate.type_id != null) {
        let fund = this.TypeReglement.filter(
          i => i.id == this.reglementToUpdate.type_id
        );
        return fund && fund.length ? fund[0].name : null;
      }
      return this.reglementToUpdate.type_id + '';
    },
    computedReg() {
      return {
        filiale_id: this.reglementToUpdate.client_id,
        type_reglement: this.reglementToUpdate.type_id,
        unite: this.reglementToUpdate.currency,
        montant_a_payer: this.reglementToUpdate.montant
      };
    },

    computedItemsFiles() {
      return function(item) {
        return item.files;
      };
    },
    returnTableFiltre() {
      if (
        this.vendeur.length ||
        this.acheteur.length ||
        this.type_partenaire.length ||
        this.clients.length ||
        this.type.length
      ) {
        return true;
      }
      return false;
    },
    returnTableChips() {
      if (
        this.date_debut !== null ||
        this.date_fin !== null ||
        this.num !== null ||
        this.comptabilise !== null ||
        this.etat !== null ||
        this.lettre !== null
      ) {
        return true;
      }
      return false;
    },
    totalPages() {
      if (this.getReglementTotalRows) {
        return Math.ceil(this.getReglementTotalRows / this.per_page);
      }
      return this.getReglementTotalRows;
    },
    computedFilterVendeur() {
      return this.getAcheteurReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedEtatReglementFilter() {
      return function(etat) {
        if (etat == 1) {
          return 'Règlement validées';
        } else {
          return 'Règlement non validées';
        }
      };
    },
    computedEtatLettrageFilter() {
      return function(lettre) {
        if (lettre == 'lettre') {
          return 'Règlement lettré';
        } else {
          return 'Règlement non lettré';
        }
      };
    },
    computedEtatReglement() {
      return [
        {
          text: 'Tous',
          value: null
        },
        {
          text: 'Règlements validées',
          value: 1
        },
        {
          text: 'Règlements non validées',
          value: 0
        }
      ];
    },
    computedEtatLettrage() {
      return [
        {
          text: 'Tous',
          value: null
        },
        {
          text: 'Règlements lettrés',
          value: 'lettre'
        },
        {
          text: 'Règlements non lettrés',
          value: 'non_lettre'
        }
      ];
    },
    computedPaiementNameSelectedLcr() {
      if (this.reglementToUpdate && this.reglementToUpdate.type_id != null) {
        let fund = this.TypeReglement.filter(
          i => i.id == this.reglementToUpdate.type_id
        );
        return fund && fund.length ? fund[0].name : null;
      }
      return this.reglementToUpdate.type_id + '';
    },
    computedCheckRowsSelected() {
      let selected = [];
      this?.reglements?.map(reglement => {
        if (reglement.check == true) {
          selected.push(reglement);
        }
      });
      return selected;
    },

    computedCheckChipFilter() {
      if (
        this.type.length > 0 ||
        this.clients.length > 0 ||
        this.type_partenaire.length > 0 ||
        this.vendeur.length > 0 ||
        this.acheteur.length > 0
      ) {
        return true;
      }
      return false;
    },
    computedFilterClient() {
      return this.clientFilter.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedTypePartenaire() {
      let typePartenaire = [
        { text: 'Client', value: 'Client' },
        { text: 'Fournisseur', value: 'Fournisseur' }
      ];
      return typePartenaire;
    },
    computedTypeReglement() {
      return this.TypeReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedFields() {
      let fields = [
        {
          value: 'check_all',
          text: '',
          sortable: false,
          show: true,
          width: '36px !important',
          padding: '0px !important',
          class: 'sticky-header-1'
        },
        {
          value: 'id',
          text: '#',
          sortable: true,
          show: this.checkPermission('RFTHACIR'),
          width: '40px !important',
          class: 'sticky-header-reglement-2'
        },
        {
          value: 'client',
          text: 'Partenaire',
          sortable: true,
          show: this.checkPermission('RFTHACP')
        },
        {
          value: 'societe',
          text: 'Client',
          sortable: true,
          show: this.checkPermission('RFTHACCL')
        },
        {
          value: 'type_partenaire',
          text: 'Type partenaire',
          sortable: true,
          show: this.checkPermission('RFTHACTP')
        },
        {
          value: 'type',
          text: 'Type réglement',
          sortable: true,
          show: this.checkPermission('RFTHACTR')
        },
        {
          value: 'montant',
          text: 'Montant TTC',
          sortable: true,
          show: this.checkPermission('RFTHACMT')
        },
        {
          value: 'balance',
          text: 'Balance',
          sortable: true,
          show: this.checkPermission('RFTHACB')
        },
        {
          value: 'payment_date',
          text: 'Date de paiement',
          sortable: true,
          show: this.checkPermission('RFTHACDP')
        },
        {
          value: 'reference',
          text: 'Réference',
          sortable: true,
          show: this.checkPermission('RFTHACR')
        },
        {
          value: 'payment_note',
          text: 'Note',
          sortable: true,
          show: this.checkPermission('RFTHACN')
        },
        {
          value: 'comptabilise',
          text: 'Comptabilisé',
          sortable: true,
          show: this.checkPermission('RFTHACC')
        },
        {
          value: 'actions',
          text: 'Actions',
          sortable: false,
          show: true,
          width: '120px !important'
        }
      ];
      return fields.filter(item => item.show == true);
    },
    computedComptabilisé() {
      let types = [];
      types.push({
        value: null,
        text: 'Tous'
      });
      for (let i = 0; i < this.typesCompta.length; i++) {
        types.push({
          value: this.typesCompta[i],
          text: this.typesCompta[i]
        });
      }

      return types;
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }

        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 42
          : 0;
      }
      if (this.computedCheckFilterSimple == true) {
        heigthBlock = heigthBlock + 42;
      }
      let hei = 129 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 42
          : 0;
      }
      if (this.computedCheckFilterSimple == true) {
        heigthBlock = heigthBlock + 42;
      }
      // calc(100vh - 228px)
      let hei = 217 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedCheckFilterSimple() {
      if (
        this.date_debut != null ||
        this.date_fin != null ||
        this.num != null ||
        this.comptabilise != null
      ) {
        return true;
      }
      return false;
    }
  },
  components: {
    addReglement: () => import('./component/addReglement.vue'),
    // Card: () => import('@/views/component/card.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    comptabilise,
    IconFiltre: () => import('@/assets/img/IconFiltre.vue'),
    PieceJointe: () => import('@/views/component/Facture/PieceJointe.vue'),
    PopoverReglementFacture: () =>
      import('@/views/component/Facture/PopoverReglementFacture.vue'),
    EditableInput: () => import('@/views/component/EditableInput.vue'),
    badge: () => import('@/views/component/badge.vue'),
    lcr: () => import('@/views/component/reglement/lcr.vue')

    // updateReglementModal: () =>
    //   import('@/views/component/reglement/updateReglementModal.vue')
  },
  methods: {
    ...mapActions([
      'getReglements',
      'getAllTypesReglement',
      'getTypeOfCompta',
      'getAllConditionsPaiement',
      'getFactureByFilialeReglement',
      'getSoldeClient',
      'getAmountNonLettrer',
      'resetMutationsReglements',
      'annulationPaymentFacture',
      'annulationPayment',
      'updateReglement',
      'deleteAttachementReglement',
      'AttachFiles',
      'getAllClientsReglement',
      'deleteReglement',
      'getSoldeEcheance',
      'updateFileReglement',
      'updatePaymentEcheance',
      'changeStatutComptabilise',
      'exportEcheanceReglement',
      'exportEcheanceReglement',
      'createReglementLcrFactureEcheances',
      'getAcheteurReglements'
    ]),
    //CODE FOR UPDATE REGLEMENT
    async handleSubmitFiles() {
      this.error = [];
      if (this.files.length != 0 || this.reglementToUpdate.files.length != 0) {
        this.loading = true;
        if (this.reglementToUpdate?.files?.length != 0) {
          let bodyFormData = new FormData();
          for (let i = 0; i < this.reglementToUpdate.files?.length; i++) {
            bodyFormData.append(
              'files[' + i + '][file_id]',
              this.reglementToUpdate.files[i].id
            );
            bodyFormData.append(
              'files[' + i + '][description]',
              this.reglementToUpdate.files[i].description
            );
          }
          const response = await this.updateFileReglement({
            bodyFormData: bodyFormData,
            reglement: this.reglementToUpdateOrigine
          });
          if (response.success) {
            if (this.files.length != 0) {
              let bodyFormData = new FormData();
              bodyFormData.append('payment_id', this.reglementToUpdate.id);
              for (let i = 0; i < this.files?.length; i++) {
                bodyFormData.append('files[' + i + '][file]', this.files[i]);
                if (this.files[i]['description'] != undefined) {
                  bodyFormData.append(
                    'files_descriptions[' + i + ']',
                    this.files[i]['description']
                  );
                }
              }
              const responseAttach = await this.AttachFiles({
                bodyFormData: bodyFormData,
                payment_id: this.reglementToUpdate.id
              });
              if (responseAttach.success) {
                this.loading = false;
                this.files = [];
                return true;
              }
            } else {
              this.files = [];
              this.loading = false;
              return true;
            }
          }
        } else {
          if (this.files.length != 0) {
            let bodyFormData = new FormData();
            bodyFormData.append('payment_id', this.reglementToUpdate.id);
            for (let i = 0; i < this.files?.length; i++) {
              bodyFormData.append('files[' + i + '][file]', this.files[i]);
              if (this.files[i]['description'] != undefined) {
                bodyFormData.append(
                  'files_descriptions[' + i + ']',
                  this.files[i]['description']
                );
              }
            }
            const responseAttachFiles = await this.AttachFiles({
              bodyFormData: bodyFormData,
              payment_id: this.reglementToUpdate.id
            });

            if (responseAttachFiles.success) {
              this.loading = false;
              return true;
            }
          }
        }
      }
    },

    checkFacture(item) {
      let dataCheck = this.facturesReglement.filter(
        i => i.check == true && i.id != item.id
      );
      if (
        dataCheck.length == 0 ||
        (dataCheck.length > 0 && dataCheck[0].vendeur_id == item.vendeur_id)
      ) {
        this.reglementToUpdate.balance = parseFloat(
          this.reglementToUpdate.balance
        );
        this.reglementToUpdate.solde = parseFloat(this.reglementToUpdate.solde);
        if (this.reglementToUpdate.balance > 0) {
          if (item.check == true) {
            if (this.reglementToUpdate.balance > item.resteInitial) {
              this.reglementToUpdate.balance =
                this.reglementToUpdate.balance - parseFloat(item.resteInitial);
              this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
                2
              );
              item.reste = 0;
              item.montant_a_regle =
                parseFloat(item.montant_a_regle) +
                parseFloat(item.resteInitial);
              this.reglementToUpdate.solde =
                this.reglementToUpdate.solde - parseFloat(item.montant_a_regle);
              this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
                2
              );
              this.reglementToUpdate.solde = Math.abs(
                this.reglementToUpdate.solde
              );
              if (this.computedCheckAll == true) {
                this.checkAll = true;
              } else {
                this.checkAll = false;
              }
            } else {
              item.montant_a_regle = this.reglementToUpdate.balance.toFixed(2);
              item.reste =
                parseFloat(item.resteInitial) - this.reglementToUpdate.balance;
              item.reste = item.reste.toFixed(2);
              this.reglementToUpdate.balance = 0;
              this.reglementToUpdate.solde =
                this.reglementToUpdate.solde - parseFloat(item.montant_a_regle);
              this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
                2
              );
              this.reglementToUpdate.solde = Math.abs(
                this.reglementToUpdate.solde
              );
              if (this.computedCheckAll == true) {
                this.checkAll = true;
              } else {
                this.checkAll = false;
              }
            }
          } else {
            this.reglementToUpdate.balance =
              this.reglementToUpdate.balance + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
              2
            );
            this.reglementToUpdate.solde =
              this.reglementToUpdate.solde + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
              2
            );
            item.reste = parseFloat(item.resteInitial);
            item.montant_a_regle = parseFloat(item.montant_a_regle_initial);
            if (this.computedCheckAll == true) {
              this.checkAll = true;
            } else {
              this.checkAll = false;
            }
          }
        } else {
          if (item.check == false) {
            this.reglementToUpdate.balance =
              this.reglementToUpdate.balance + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
              2
            );
            this.reglementToUpdate.solde =
              this.reglementToUpdate.solde + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
              2
            );
            item.reste = parseFloat(item.resteInitial);
            item.montant_a_regle = parseFloat(item.montant_a_regle_initial);
            this.checkAll = false;
          } else {
            item.check = false;
            this.checkAll = false;
          }
        }
      } else {
        item.check = !item.check;
      }
    },

    async getfactures() {
      this.show = true;
      const response = await this.getFactureByFilialeReglement({
        filiale_id: this.reglementToUpdate.client_id,
        type_partenaire: this.reglementToUpdate.type_partenaire
      });
      if (response) {
        this.reglementToUpdate.balance = this.reglementToUpdate.balance_initial;
        this.show = false;
      }
    },

    async handleSubmitUpdateReglement() {
      if (this.reglementToUpdate.montant == 0) {
        this.error = 'Il faut avoir un montant à payer supèrieur à 0 ';
      } else {
        this.loading = true;
        this.reglementToUpdate.echeances.forEach(async (element, index) => {
          let bodyFormDataEcheance = new FormData();
          bodyFormDataEcheance.append('id', element.id);
          bodyFormDataEcheance.append('libelle', element.libelle);
          bodyFormDataEcheance.append('client_id', element.client_id);
          bodyFormDataEcheance.append('date_creation', element.date_creation);
          bodyFormDataEcheance.append('date_echeance', element.date_echeance);
          bodyFormDataEcheance.append(
            'beneficiaire_id',
            element.beneficiaire_id
          );
          bodyFormDataEcheance.append('valeur_en', element.valeur_en);
          bodyFormDataEcheance.append('date_envoi', element.date_envoi);
          bodyFormDataEcheance.append('date_signature', element.date_signature);
          bodyFormDataEcheance.append('date_valeur', element.date_valeur);
          bodyFormDataEcheance.append('devise', element.devise);
          bodyFormDataEcheance.append('amount', element.amount);
          bodyFormDataEcheance.append('rib_id', element.rib.id);
          bodyFormDataEcheance.append('sequence', element.sequence);
          bodyFormDataEcheance.append('etat', element.etat);
          bodyFormDataEcheance.append('ordre_de', element.ordre_de);
          const response = await this.updatePaymentEcheance(
            bodyFormDataEcheance
          );
          if (response.succes) {
            this.reglementToUpdate.echeances[index].amount =
              response.data.amount;
            this.reglementToUpdate.echeances[index].date_creation =
              response.data.date_creation;
            this.reglementToUpdate.echeances[index].date_echeance =
              response.data.date_echeance;
            this.reglementToUpdate.echeances[index].date_signature =
              response.data.date_signature;
            this.reglementToUpdate.echeances[index].date_valeur =
              response.data.date_valeur;
            this.reglementToUpdate.echeances[index].date_envoi =
              response.data.date_envoi;
          }
        });
        let bodyFormData = new FormData();
        bodyFormData.append('payment_id', this.reglementToUpdate.id);
        bodyFormData.append('balance', this.reglementToUpdate.balance);
        bodyFormData.append('amount', this.reglementToUpdate.montant);
        bodyFormData.append('currency', this.reglementToUpdate.currency);
        bodyFormData.append('canConfirm', this.reglementToUpdate.canConfirm);
        bodyFormData.append(
          'confirmation_fournisseur',
          this.reglementToUpdate.confirmation_fournisseur
        );
        bodyFormData.append(
          'payment_date',
          this.reglementToUpdate.payment_date
        );
        bodyFormData.append('type_id', this.reglementToUpdate.type_id);
        if (!this.computedPaiementNameSelected.includes('LCR')) {
          bodyFormData.append(
            'payment_condition_id',
            this.reglementToUpdate.condition_id
          );
        }
        bodyFormData.append('filiale_id', this.reglementToUpdate.client_id);
        bodyFormData.append(
          'payment_note',
          this.reglementToUpdate.payment_note
        );
        bodyFormData.append('reference', this.reglementToUpdate.reference);
        bodyFormData.append(
          'type_partenaire',
          this.reglementToUpdate.type_partenaire
        );

        for (let i = 0; i < this.facturesReglement.length; i++) {
          if (this.facturesReglement[i].check == true) {
            bodyFormData.append(
              'factures[' + i + '][id]',
              this.facturesReglement[i].id
            );
            bodyFormData.append(
              'factures[' + i + '][montant_regle]',
              this.facturesReglement[i].montant_a_regle
            );
            bodyFormData.append(
              'factures[' + i + '][total_ttc]',
              this.facturesReglement[i].total_ttc
            );
          }
        }
        const responseUpdate = await this.updateReglement({
          bodyFormData: bodyFormData,
          oldReglement: this.reglementToUpdateOrigine
        });
        if (responseUpdate.succes) {
          if (
            this.dataLcr &&
            this.dataLcr?.lcr &&
            this.dataLcr?.lcr?.length != 0 &&
            this.reglementToUpdate.type_id != null &&
            this.computedPaiementNameSelectedLcr.includes('LCR') &&
            this.reglementToUpdate.echeances.length == 0
          ) {
            let bodyFormDataEch = new FormData();
            this.dataLcr?.lcr?.forEach((element, i) => {
              bodyFormDataEch.append(
                'echeances[' + i + '][libelle]',
                element.libelle
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][client_id]',
                this.dataLcr.client_id
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][date_echeance]',
                element.date
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][beneficiaire_id]',
                this.dataLcr.beneficiaire_id
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][valeur_en]',
                this.dataLcr.valeur_en
              );
              bodyFormDataEch.append('echeances[' + i + '][date_envoi]', null);
              bodyFormDataEch.append(
                'echeances[' + i + '][date_signature]',
                null
              );
              bodyFormDataEch.append('echeances[' + i + '][date_valeur]', null);
              bodyFormDataEch.append(
                'echeances[' + i + '][devise]',
                this.dataLcr.devise
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][amount]',
                element.value
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][rib_id]',
                this.dataLcr.rib.id
              );
            });
            const resEch = await this.createReglementLcrFactureEcheances({
              response: this.reglementToUpdateOrigine,
              data: bodyFormDataEch
            });
            if (resEch) {
              let total = 0;
              this.reglementToUpdate.echeances.map(item => {
                total = total + item.amount;
              });
              this.reglementToUpdate.totalEcheance = total.toFixed(2);
            }
          }
          const responseFile = await this.handleSubmitFiles();
          if (responseFile) {
            this.files = [];
          }
          this.loading = false;
          this.show = true;
          this.getAmountNonLettrer({
            filiale_id: this.reglementToUpdate.client_id,
            type_partenaire: this.reglementToUpdate.type_partenaire
          });
          const responseFacture = await this.getFactureByFilialeReglement({
            filiale_id: this.reglementToUpdate.client_id,
            type_partenaire: this.reglementToUpdate.type_partenaire
          });
          if (responseFacture) {
            this.clientsReglement.map(item => {
              if (item.id == this.reglementToUpdate.client_id) {
                this.vendeur = item.name;
              }
            });
            this.getAmountNonLettrer({
              filiale_id: this.reglementToUpdate.client_id,
              type_partenaire: this.reglementToUpdate.type_partenaire
            });
            const responsegetSoldeClient = await this.getSoldeClient({
              id: this.reglementToUpdate.client_id,
              type_partenaire: this.reglementToUpdate.type_partenaire
            });
            if (responsegetSoldeClient) {
              this.reglementToUpdate.solde = this.soldeClient;
              this.reglementToUpdate.solde_init = this.soldeClient;
              const responseEcheance = await this.getSoldeEcheance({
                id: this.reglementToUpdate.client_id,
                type_partenaire: this.reglementToUpdate.type_partenaire
              });
              if (responseEcheance) {
                this.reglementToUpdate.solde_echeance = this.soldeEcheance;
                this.show = false;
              }
              // if (response) {
              //   this.reglementToUpdate.solde_echeance = this.soldeEcheance;
              //   this.show = false;
              // }
            }
          }
        } else {
          this.loading = false;
        }
      }
    },

    handleModalAnnulerTotal() {
      this.annulationTotal = true;
    },
    async downloadFacture(id) {
      let bodyFormData = new FormData();
      bodyFormData.append('ids[0]', id);
      await this.exportEcheanceReglement(bodyFormData);
    },

    functionCheckAllEcheance() {
      this.reglementToUpdate.echeances.map(item => {
        item.check = this.checkAllEcheance;
      });
    },
    checkRowsEcheances(item) {
      let checked = [];
      if (item.check == false && this.checkAllEcheance == true) {
        this.checkAllEcheance = false;
      }
      this.reglementToUpdate?.echeances?.map(facture => {
        if (facture.check == true) {
          checked.push(facture);
        }
      });
      if (checked.length == this.reglementToUpdate.echeances.length) {
        this.checkAllEcheance = true;
      }
    },

    async exportMultipleEcheance() {
      let bodyFormData = new FormData();
      let checked = [];
      checked = this.reglementToUpdate.echeances.filter(
        item => item.check == true
      );
      checked.forEach((element, index) => {
        bodyFormData.append('ids[' + index + ']', element.id);
      });
      const response = await this.exportEcheanceReglement(bodyFormData);
      if (response) {
        this.reglementToUpdate.echeances.forEach((element, index) => {
          element.check = false;
        });
        this.checkAllEcheance = false;
      }
    },

    showFiles(file) {
      this.files = file;
    },
    showFilesRecent(files) {
      this.reglementToUpdate.files = [...files];
    },
    // a verifier le payload
    updateMontantRegler(payload) {
      this.reglementToUpdate.balance = parseFloat(
        this.reglementToUpdate.balance
      );
      this.reglementToUpdate.solde = parseFloat(this.reglementToUpdate.solde);

      if (
        parseFloat(payload.row.montant_a_regle) +
          parseFloat(this.reglementToUpdate.balance) >
        parseFloat(payload.value)
      ) {
        payload.row.montant_a_regle = parseFloat(payload.value).toFixed(2);
        payload.row.reste = parseFloat(
          payload.row.resteInitial - parseFloat(payload.value).toFixed(2)
        );
        payload.row.reste = payload.row.reste.toFixed(2);
        payload.row.reste = parseFloat(payload.row.reste);
        this.reglementToUpdate.balance =
          this.reglementToUpdate.balance + parseFloat(payload.row.resteInitial);
        this.reglementToUpdate.balance =
          this.reglementToUpdate.balance - parseFloat(payload.value);
        this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
          2
        );
        this.reglementToUpdate.solde =
          this.reglementToUpdate.solde + parseFloat(payload.row.resteInitial);
        this.reglementToUpdate.solde =
          this.reglementToUpdate.solde - parseFloat(payload.value);
        this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(2);
      }
    },

    handleCheckAll() {
      if (this.checkAll == true) {
        let table = this.facturesReglementComputed.filter(
          item => item.check == false
        );
        table.map(item => {
          if (this.reglementToUpdate.balance > 0) {
            item.check = true;
            this.checkFacture(item);
          }
        });
      } else {
        let table = this.facturesReglementComputed.filter(
          item => item.check == true
        );
        table.map(item => {
          if (item.check == true) {
            item.check = false;
            this.checkFacture(item);
          }
        });
      }
    },
    selectAll() {
      this.reglementToUpdate.factures_regles.map(
        i => (i.check = this.checkAll)
      );
    },

    async submitAnnulationReglement() {
      this.annulerReglement = true;
      let bodyFormData = new FormData();
      let indexAnnulationReglement = 0;
      for (let j = 0; j < this.reglementToUpdate.factures_regles.length; j++) {
        if (this.reglementToUpdate.factures_regles[j].check == true) {
          for (
            let i = 0;
            i < this.reglementToUpdate.factures_regles[j].reglements.length;
            i++
          ) {
            bodyFormData.append(
              'obj[' + indexAnnulationReglement + '][facture_id]',
              this.reglementToUpdate.factures_regles[j].reglements[i].facture_id
            );
            bodyFormData.append(
              'obj[' + indexAnnulationReglement + '][payment_facture_id]',
              this.reglementToUpdate.factures_regles[j].reglements[i].id
            );
            indexAnnulationReglement = indexAnnulationReglement + 1;
          }
        }
      }
      const response = await this.annulationPaymentFacture({
        bodyFormData: bodyFormData,
        oldReglement: this.reglementToUpdateOrigine
      });
      if (response.succes) {
        this.annulerReglement = false;
        this.hideModal('confirmAnnulation');
      }
    },

    openModalConfirmAnnulation() {
      this.confirmAnnulation = true;
    },
    calculRestePayer() {
      if (
        parseFloat(this.reglementToUpdate.montant) >
        this.reglementToUpdate.montant_initial
      ) {
        let montant =
          parseFloat(this.reglementToUpdate.montant) -
          this.reglementToUpdate.montant_initial;
        this.reglementToUpdate.balance =
          parseFloat(this.reglementToUpdate.balance) + montant;
        let TableF = this.facturesReglement?.filter(
          item => item.check == false
        );
        if (TableF.length != this.facturesReglement.length) {
          this.getFacturesFiliales();
        }
      }
    },

    async handleUpdate(item) {
      this.updateReglementFacture = true;
      this.show = true;
      let total = 0;
      this.reglementToUpdateOrigine = item;
      this.reglementToUpdate = { ...item };
      this.reglementToUpdate.echeances.map(item => {
        total = total + item.amount;
      });
      if (this.reglementToUpdate && this.reglementToUpdate.type_id != null) {
        this.computedTypeReglement.map(item => {
          if (
            item.value == this.reglementToUpdate.type_id &&
            item.text == 'LCR Lettre de change Relevé'
          ) {
            this.showEcheance = true;
          }
        });
      }
      this.reglementToUpdate.totalEcheance = total.toFixed(2);
      this.reglement = {
        filiale_id: item.client_id,
        type_reglement: item.type_id,
        unite: item.currency,
        montant_a_payer: item.montant
      };
      this.getAmountNonLettrer({
        filiale_id: this.reglementToUpdate.client_id,
        type_partenaire: this.reglementToUpdate.type_partenaire
      });
      const responsegetSoldeClient = await this.getSoldeClient({
        id: this.reglementToUpdate.client_id,
        type_partenaire: this.reglementToUpdate.type_partenaire
      });
      if (responsegetSoldeClient) {
        this.reglementToUpdate.solde = this.soldeClient;
        this.reglementToUpdate.solde_init = this.soldeClient;
        const responsegetSoldeEcheance = await this.getSoldeEcheance({
          id: this.reglementToUpdate.client_id,
          type_partenaire: this.reglementToUpdate.type_partenaire
        });
        if (responsegetSoldeEcheance) {
          this.reglementToUpdate.solde_echeance = this.soldeEcheance;
        }
      }
      this.show = false;
    },
    saveDataForLcr(data) {
      this.dataLcr = data;
    },

    openPopover() {
      this.menu = true;
    },
    closePopover() {
      this.menu = false;
      this.vendeur = [];
      this.isLocalStorageVendeur = [];
      this.acheteur = [];
      this.isLocalStorageAcheteur = [];
      this.clients = [];
      this.isLocalStorageClients = [];
      this.etat = null;
      this.isLocalStorageEtat = null;
      this.type_partenaire = [];
      this.isLocalStorageTypePartenaire = [];
      this.type = [];
      this.isLocalStorageType = [];
      this.comptabilise = null;
      this.isLocalStorageComptabilise = null;
      this.lettre = null;
      this.isLocalStorageLettre = null;
      this.handleFilter();
    },
    validerPopover() {
      //
      //SET FILTER DROM POPOVER
      //
      this.type = this.isLocalStorageType;
      this.clients = this.isLocalStorageClients;
      this.type_partenaire = this.isLocalStorageTypePartenaire;
      this.acheteur = this.isLocalStorageAcheteur;
      this.vendeur = this.isLocalStorageVendeur;
      this.comptabilise = this.isLocalStorageComptabilise;
      this.etat = this.isLocalStorageEtat;
      this.lettre = this.isLocalStorageLettre;
      //
      //END SET FILTER DROM POPOVER
      //
      this.handleFilter();
      this.menu = false;
    },
    disabledStartDate(date) {
      if (this.date_fin) {
        return (
          this.date_fin && moment(date, 'YYYY-MM-DD') <= new Date(this.date_fin)
        );
      }
      return true;
    },
    disabledEndDate(date) {
      if (this.date_debut) {
        return (
          this.date_debut &&
          moment(date, 'YYYY-MM-DD').add(1, 'days') > new Date(this.date_debut)
        );
      }
      return true;
    },
    handleResetDate(name) {
      this[name] = null;
      this.handleFilter();
      this.isDatePickerDebutVisible = false;
      this.isDatePickerFinVisible = false;
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.handleFilter();
      this.isDatePickerDebutVisible = false;
      this.isDatePickerFinVisible = false;
    },
    async listOrder(col, ord) {
      this.column = col;
      this.order = ord;
      await this.getReglements({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        num: this.num,
        type: this.type,
        clients: this.clients,
        type_partenaire: this.type_partenaire,
        comptabilise: this.comptabilise,
        page: this.page,
        order: this.order,
        column: this.column,
        vendeur: this.vendeur,
        acheteur: this.acheteur,
        etat: this.etat,
        per_page: this.per_page,
        etat_lettrage: this.lettre
      });
    },
    handleResetCheck(value) {
      if (value == false) {
        this.checkAllReglement = false;
        this?.reglements?.map(reglement => (reglement.check = false));
      }
    },
    checkRows(item) {
      let checked = [];
      if (item.check == false && this.checkAllReglement == true) {
        this.checkAllReglement = false;
      }
      this?.reglements?.map(reglement => {
        if (reglement.check == true) {
          checked.push(reglement);
        }
      });
      if (checked.length == this.reglements.length) {
        this.checkAllReglement = true;
      }
    },
    checkAllFunction() {
      this.reglements.map(facture => (facture.check = this.checkAllReglement));
    },
    changeMontantEcheance(index) {
      let sum = 0;
      let index_update =
        index == this.reglementToUpdate.echeances.length - 1
          ? 0
          : this.reglementToUpdate.echeances.length - 1;

      let index_start =
        index == this.reglementToUpdate.echeances.length - 1 ? 1 : 0;
      let index_end =
        index == this.reglementToUpdate.echeances.length - 1
          ? this.reglementToUpdate.echeances.length
          : this.reglementToUpdate.echeances.length - 1;
      this.reglementToUpdate.echeances.forEach((element, index) => {
        if (index >= index_start && index < index_end) {
          sum = parseFloat(sum) + parseFloat(element.amount);
        }
      });
      this.reglementToUpdate.echeances[index_update].amount = (
        this.reglementToUpdate.totalEcheance - sum
      ).toFixed(2);
    },
    setLocalStorageReglementFactures() {
      localStorage.setItem(
        'reglement-factures-cdg-th',
        JSON.stringify({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          clients: this.clients,
          type_partenaire: this.type_partenaire,
          type: this.type,
          per_page: this.per_page,
          page: this.page,
          num: this.num,
          comptabilise: this.comptabilise,
          lettre: this.lettre,
          vendeur: this.vendeur,
          acheteur: this.acheteur,
          etat: this.etat,
          column: this.column,
          order: this.order
        })
      );
    },
    changeSearchValue(event) {
      this.num = event;
      this.handleFilter();
    },
    remove(item, filter, fct, origin) {
      this[filter].splice(this[filter].indexOf(item), 1);
      if (origin) {
        this[origin] = this[filter];
      }
      this[fct]();
    },
    async submitDeleteReglement() {
      this.loading = true;
      const response = await this.deleteReglement(this.reglementToDelete.id);
      if (response.success) {
        this.loading = false;
        this.handleFilter();
        this.hideModal('deleteReglement');
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    handleDelete(item) {
      this.reglementToDelete = { ...item };
      this.deleteReglementModal = true;
    },
    async deleteFile(item, index) {
      const response = await this.deleteAttachementReglement(item.id);
      if (response) {
        this.reglementToUpdate.files.splice(index, 1);
      }
    },
    async getFacturesFiliales() {
      this.show = true;
      const response = await this.getFactureByFilialeReglement({
        filiale_id: this.reglementToUpdate.client_id,
        type_partenaire: this.reglementToUpdate.type_partenaire
      });
      if (response) {
        this.getAmountNonLettrer({
          filiale_id: this.reglementToAdd.filiale_id,
          type_partenaire: this.reglementToAdd.type_partenaire
        });
        const responseSolde = await this.getSoldeClient({
          id: this.reglementToAdd.filiale_id,
          type_partenaire: this.reglementToAdd.type_partenaire
        });
        if (responseSolde) {
          this.reglementToUpdate.solde = this.soldeClient;
          this.reglementToUpdate.solde_init = this.soldeClient;
          const responseEcheance = await this.getSoldeEcheance({
            id: this.reglementToUpdate.client_id,
            type_partenaire: this.reglementToUpdate.type_partenaire
          });
          if (responseEcheance) {
            this.reglementToUpdate.solde_echeance = this.soldeEcheance;
            this.show = false;
          }
        }
      }
    },

    annulerConfirmationTotal() {
      this.AnnulationTotal = false;
    },
    async AnnulerReglementTotal() {
      this.loadingValideAnnuler = true;
      const response = await this.annulationPayment({
        id: this.reglementToUpdate.id,
        oldReglement: this.reglementToUpdateOrigine
      });
      if (response) {
        this.loadingValideAnnuler = false;
        this.AnnulationTotal = false;
        this.hideModal('updateReglementFacture');
      }
    },

    annulerConfirmation() {
      this.confirmAnnulation = false;
      this.reglementToUpdate.factures_regles.map(reglement => {
        if (reglement.check == true) {
          reglement.check = false;
        }
      });
    },
    resetModal() {
      this.loading = false;
      this.show = false;
      this.checkAll = false;
      this.reglementToDelete = {};
      this.error = [];
      this.indexTab = 'tab-0';
    },
    hideModal(ref) {
      this[ref] = false;
      // if (ref == 'deleteReglement') {
      //   this.deleteReglementModal = false;
      // } else {
      //   this.$refs[ref].hide();
      // }
      this.resetModal();
    },

    handleFilter() {
      this.setLocalStorageReglementFactures();
      this.$nextTick(() => {
        this.getReglements({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          num: this.num,
          type: this.type,
          clients: this.clients,
          type_partenaire: this.type_partenaire,
          comptabilise: this.comptabilise,
          page: this.page,
          order: this.order,
          column: this.column,
          vendeur: this.vendeur,
          acheteur: this.acheteur,
          etat: this.etat,
          per_page: this.per_page,
          etat_lettrage: this.lettre
        });
      });
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    }
  },
  filters: {
    formateValue: value => {
      if (value && value != null && value != '') {
        return parseFloat(value)?.toFixed(2);
      }
      return value;
    },
    numberWithSpaces: x => {
      // return new Intl.NumberFormat().format(x);
      if (x != null && x != undefined) {
        if (Math.sign(x) === -1) {
          var y = Math.abs(x);
          var parts = y.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          return '-' + parts.join('.');
        } else {
          var parts = x.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          return parts.join('.');
        }
      }
    }
  },
  async mounted() {
    if (localStorage.getItem('reglement-factures-cdg-th')) {
      let localData = JSON.parse(
        localStorage.getItem('reglement-factures-cdg-th')
      );
      this.date_debut = localData.date_debut;
      this.date_fin = localData.date_fin;
      this.type = localData.type;
      this.isLocalStorageType = this.type;
      this.clients = localData.clients;
      this.isLocalStorageClients = this.clients;
      this.type_partenaire = localData.type_partenaire;
      this.isLocalStorageTypePartenaire = this.type_partenaire;
      this.num = localData.num;
      this.comptabilise = localData.comptabilise;
      this.isLocalStorageComptabilise = this.comptabilise;
      this.lettre = localData.lettre;
      this.isLocalStorageLettre = this.lettre;
      this.vendeur = localData.vendeur;
      this.isLocalStorageVendeur = this.vendeur;
      this.acheteur = localData.acheteur;
      this.isLocalStorageAcheteur = this.acheteur;
      this.etat = localData.etat;
      this.isLocalStorageEtat = this.etat;
      this.column = localData.column;
      this.order = localData.order;
    } else {
      this.setLocalStorageReglementFactures();
    }
    this.getAcheteurReglements();
    this.getAllClientsReglement();
    this.getTypeOfCompta();
    this.getAllTypesReglement();
    this.getAllConditionsPaiement();
    this.handleFilter();
  }
};
</script>
<style lang="scss">
.groupe-content-reglement-fac {
  display: flex;
  align-items: start;
  &.padding-table {
    padding-top: 19px;
  }
  .btn-color-fac {
    background-color: #ffffff;
    // border-radius: 41px;
    // width: 74px;
    width: 37px;
    position: relative;
    // margin-right: 14px;
    display: inline;
    text-align: center;
    padding: 5px 1px;
    .ligne {
      width: 70%;
      margin: auto;
      margin-top: 0px;
      margin-bottom: 5px;
    }
  }
  .table-reglement-fac {
    height: 100% !important;
    max-height: 100% !important;
    overflow: auto;
    width: 100%;
    // border-top: 1px solid #e5e5e5;
    .theme--light.v-data-table {
      background-color: #ffffff;
      border: 1px solid #e5e5e5;
      color: rgba(0, 0, 0, 0.87);
    }
    .text-center {
      text-align: center;
    }
    .style-arrow-not-selected {
      width: 14px;
      height: 14px;
      color: #aaacb0;
      margin-left: 1px;
      margin-right: 1px;
    }
    .style-arrow-selected {
      width: 14px;
      height: 14px;
      color: #704ad1;
      margin-left: 1px;
      margin-right: 1px;
    }
    .table {
      table-layout: fixed !important;
      border-collapse: collapse !important;
    }

    .table-facture {
      .v-data-table__wrapper {
        // height: calc(100vh - 160px);
        height: calc(100vh - 170px) !important;
      }

      &.height-filter-tous {
        .v-data-table__wrapper {
          height: calc(100vh - 278px) !important;
        }
      }
      &.height-filter-chips-table {
        .v-data-table__wrapper {
          height: calc(100vh - 200px) !important;
        }
      }
      &.height-filter-table {
        .v-data-table__wrapper {
          height: calc(100vh - 245px) !important;
        }
      }
    }
  }
}
.interface-menu-navbar-header {
  .content-cadre-table-reglement-fac {
    background-color: #fff;
    border-top: 0px;
    // padding: 9px 18px 0px 18px;
    padding: 9px 12px 0px 7px;
    height: calc(100vh - 150px);
  }
  .footer-style-table {
    display: flex;
    justify-content: space-between;
    // padding: 6px 26px 0px 82px;
    padding: 0px 20px 0px 36px;
  }
}
.popover-menu-reglement-facture {
  background-color: #704ad1;
  right: 7px;
  left: unset !important;
  top: 97px !important;
}
.header-reglement {
  border: 0px;
  position: sticky;
  top: -1px;
  background-color: #fff;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  z-index: 7;
  .sticky-header-1 {
    background-color: #fff;
    position: sticky;
    left: 0px;
    z-index: 10;
    top: 0px;
  }
  .sticky-header-reglement-2 {
    background-color: #fff;
    position: sticky;
    left: 37px;
    z-index: 10;
    top: 0px;
  }
  th {
    border: 1px solid #e5e5e5;
    font-size: 11px;
    font-weight: 600;
    color: black;
    font-family: 'Montserrat', sans-serif;
    text-transform: lowercase;
    white-space: nowrap;
    height: 50px !important;
  }
  .v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
}
.sticky-header-reglement-2 {
  background-color: #fff;
  position: sticky;
  left: 37px;
  z-index: 10;
  top: 0px;
}
.space-table-filter-reglement {
  padding-top: 0px;
  // margin-bottom: 8px;
}
</style>
